import Component from 'vue-class-component'
import BasePage from '@/pages/Base'
import forEach from 'lodash/forEach'
import { Watch } from 'vue-property-decorator'
import uniqBy from 'lodash/uniqBy'

import ShipmentServis from '@/services/Shipments/ShipmentList'
import OrderService from '@/services/Orders'

@Component({})
export default class ShipmentEdit extends BasePage {
  constructor(){
    super()
  }

      id = 0
      blNumber: string = ''
      portOrigin: string = ''
      portDestination: string = ''
      shipments_date: any = {
        departureDate: {
          open: false,
          date: null,
          dateFormatted: null
        },
        arrivalDate: {
          open: false,
          date: null,
          dateFormatted: null
        }
      }
      onDepartureDateCancel() {
        this.shipments_date.departureDate.open = false
      }
      onDepartureDateDone() {
        this.shipments_date.departureDate.dateFormatted = this.toFullDate(this.shipments_date.departureDate.date)
        this.shipments_date.departureDate.open = false
      }
      onArrivalDateCancel() {
        this.shipments_date.arrivalDate.open = false
      }
      onArrivalDateDone() {
        this.shipments_date.arrivalDate.dateFormatted = this.toFullDate(this.shipments_date.arrivalDate.date)
        this.shipments_date.arrivalDate.open = false
      }

      packing: any = {
        items: [],
        loading: false,
        headers: [
          {
            text: 'Packing List ID',
            align: 'center',
            sortable: false,
            class: 'table-cell-width'
          },
          {
            text: 'Order ID',
            align: 'center',
            sortable: false,
            class: 'table-cell-width'
          },
          {
            text: 'Tracking Code',
            align: 'center',
            sortable: false,
            class: 'table-cell-width'
          },
          {
            text: 'Tracking Status',
            align: 'center',
            sortable: false,
            class: 'table-cell-width'
          },
          {
            text: 'Qty',
            align: 'center',
            sortable: false,
            class: 'table-cell-width'
          },
          {
            text: 'CBM',
            align: 'center',
            sortable: false,
            class: 'table-cell-width'
          },
          {
            text: 'Weight Kgs',
            align: 'center',
            sortable: false,
            class: 'table-cell-width'
          },
          {
            text: 'Action',
            align: 'center',
            sortable: false,
            class: 'table-cell-width'
          }
        ]
      }

      modalAddPacking : any = {
        open: false,
        freightMethod: '',
        tableLoading: false,
        tableItems: [],
        tableHeaders: [
          {
            text: 'Customer Name',
            align: 'center',
            sortable: false
          },
          {
            text: 'Order ID',
            align: 'left',
            sortable: false
          },
          {
            text: 'Shipping Method',
            align: 'left',
            sortable: false
          } 
        ],
        tableHeadersExpand: [
          {
            text: '',
            align: 'center',
            sortable: false,
            class: 'table-cell-width'
          },
          {
            text: 'Packing List ID',
            align: 'left',
            sortable: true,
            class: 'table-cell-width', 
            value: 'id'
          },
          {
            text: 'Tracking Code',
            align: 'center',
            sortable: true,
            class: 'table-cell-width',
            value: 'tracking_code'
          },
          {
            text: 'Tracking Status',
            align: 'center',
            sortable: false,
            class: 'table-cell-width'
          },
          {
            text: 'Qty',
            align: 'center',
            sortable: false,
            class: 'table-cell-width'
          },
          {
            text: 'CBM',
            align: 'center',
            sortable: false,
            class: 'table-cell-width'
          },
          {
            text: 'Weight Kgs',
            align: 'center',
            sortable: false,
            class: 'table-cell-width'
          }
        ],
        selectedExpand: [],
        tableLoadingExpand: false,
        tableTotalItems: 0,
        tableRowsPerPageItems: [5, 10, 15, 25, 50, 100],
        tablePagination: {
          sortBy: 'created_at',
          page: 1,
          rowsPerPage: 5,
          descending: true,
          totalItems: 0
        }
      }

      mounted() {
        this.getPacking()
      }

      @Watch('modalAddPacking.tablePagination')
      @Watch('modalAddPacking.open')
      
      onChanged(){
        if(this.modalAddPacking.open == true) this.OpenModal_AddPacking()
      }

      async getPacking(){
        this.packing.loading = true
        const opts: any = {
          params: {
            include: 'packages,orders'
          }
        }
        try{
          const responses = await ShipmentServis.getShipmentListDetail(this.$route.params.id, opts)
          const shipment_data = responses.data.data.attributes

          this.id = shipment_data.id
          this.blNumber = shipment_data.bl_number
          this.portOrigin = shipment_data.from
          this.portDestination = shipment_data.to
          this.shipments_date.departureDate.date = shipment_data.departure_date.slice(6, 10) + shipment_data.departure_date.slice(2, 5) + '-' + shipment_data.departure_date.slice(0, 2)
          this.shipments_date.departureDate.dateFormatted = this.toFullDate(this.shipments_date.departureDate.date)
          this.shipments_date.arrivalDate.date = shipment_data.arrival_date.slice(6, 10) + shipment_data.arrival_date.slice(2, 5) + '-' + shipment_data.arrival_date.slice(0, 2)
          this.shipments_date.arrivalDate.dateFormatted = this.toFullDate(this.shipments_date.arrivalDate.date)
          this.modalAddPacking.freightMethod = shipment_data.shipment_method
          this.packing.items = []
          for(const items_packing of responses.data.data.relationships.packages){
            const list_items = {
              ...responses.data.included.packages[Number(items_packing.id)].attributes,
              order_code: null,
              dimensions: {
                qty: 0,
                cbm: 0,
                weight_kgs: 0
              }
            }
            const opts: any = {
              params: {
                include: 'packaging,newstatus,order',
                'filter[id][in]': items_packing.id
              }
            }
            ShipmentServis.getPackingList(opts).then(responses => {
              forEach(responses.data.included.packaging, packaging => {
                var dimens = 0
                if(packaging.attributes.dimension_unit === 'meters'){
                  dimens = packaging.attributes.dimensions[0].value * packaging.attributes.dimensions[1].value * packaging.attributes.dimensions[2].value
                }else if(packaging.attributes.dimension_unit === 'inchs'){
                  dimens = (packaging.attributes.dimensions[0].value/39.37)*(packaging.attributes.dimensions[1].value/39.37)*(packaging.attributes.dimensions[2].value/39.37)
                }else if(packaging.attributes.dimension_unit === 'cms'){
                  dimens = (packaging.attributes.dimensions[0].value/100)*(packaging.attributes.dimensions[1].value/100)*(packaging.attributes.dimensions[2].value/100)
                }
                list_items.dimensions.qty = list_items.dimensions.qty + packaging.attributes.package_qty
                list_items.dimensions.cbm = list_items.dimensions.cbm + dimens
                list_items.dimensions.weight_kgs = list_items.dimensions.weight_kgs + packaging.attributes.gross_weight
              })

              const array_order = responses.data.data[0].relationships.order.id
              list_items.order_code = responses.data.included.order[array_order].attributes.order_code 
            })

            this.packing.items.push(list_items)
          }
        } catch (error){
          this.catchHandler(error)
        } finally {
          this.packing.loading = false
        }
      }

      deleteItem_packing (data) {
        const index = this.packing.items.indexOf(data)
        // confirm('Are you sure you want to delete this item?') && this.packing.items.splice(index, 1)
        this.packing.items.splice(index, 1)
      }

      async OpenModal_AddPacking(){
        this.modalAddPacking.tableLoading = true
        const opts_order: any = {
          params: {
            'page[num]': this.modalAddPacking.tablePagination.page,
            'page[limit]': this.modalAddPacking.tablePagination.rowsPerPage,
            sort: '-created_at',
            include: 'package',
            for: 'shipment',
            'filter[shipment_method][is]': ''
          }
        }
        if (!this.isEmpty(this.modalAddPacking.freightMethod)) {
          opts_order.params['filter[shipment_method][is]'] = this.modalAddPacking.freightMethod
        }

        const opts_package: any = {
          params: {
            for: 'shipment',
            include: 'status,packaging'
          }
        }

        try{
          const responses = await OrderService.getOrders(opts_order)
          const responsesData = responses.data
          const responsesMeta = responses.meta

          this.modalAddPacking.tableItems = []
          opts_package.params['filter[order_id][is]'] = null

          for (const dataOrders of responsesData){
            opts_package.params['filter[order_id][is]'] = dataOrders.id
            const responsesPackage = await ShipmentServis.getPackageNotShipment(opts_package)
            const Packaging = responsesPackage.data.included.packaging
                        
            const order = {
              ...dataOrders.attributes,
              package: []
            }

            for (const package_data of responsesPackage.data.data){
              const data = {
                ...package_data.attributes,
                dimensions: {
                  qty : 0,
                  cbm : 0,
                  weight_kgs : 0
                }
              }
              for (const packaging_items of package_data.relationships.packaging){
                const arrayPackaging = Packaging[Number(packaging_items.id)].attributes
                var dimens = 0
                if(arrayPackaging.dimension_unit === 'meters'){
                  dimens = arrayPackaging.dimensions[0].value * arrayPackaging.dimensions[1].value * arrayPackaging.dimensions[2].value
                }else if(arrayPackaging.dimension_unit === 'inchs'){
                  dimens = (arrayPackaging.dimensions[0].value/39.37)*(arrayPackaging.dimensions[1].value/39.37)*(arrayPackaging.dimensions[2].value/39.37)
                }else if(arrayPackaging.dimension_unit === 'cms'){
                  dimens = (arrayPackaging.dimensions[0].value/100)*(arrayPackaging.dimensions[1].value/100)*(arrayPackaging.dimensions[2].value/100)
                }
                data.dimensions.qty = data.dimensions.qty + arrayPackaging.package_qty
                data.dimensions.cbm = data.dimensions.cbm + dimens
                data.dimensions.weight_kgs = data.dimensions.weight_kgs + arrayPackaging.gross_weight 
              }

              order.package.push(data)
            }

            this.modalAddPacking.tableItems.push(order)
          }
          this.modalAddPacking.tableItems = uniqBy(this.modalAddPacking.tableItems, 'id')

          this.modalAddPacking.tableTotalItems = responsesMeta.pagination.total
          this.modalAddPacking.tablePagination.totalItems = responsesMeta.total

        } catch (error) {
          this.catchHandler(error)
        } finally {
          this.modalAddPacking.tableLoading = false
        }
      }
      CloseModal_AddPacking(){
        this.modalAddPacking.open= false
      }
      SaveModal_AddPacking(){
        if (this.modalAddPacking.selectedExpand.length > 0){
          for (const addPacking_item of this.modalAddPacking.selectedExpand){
            this.packing.items.push(addPacking_item)
          }

          this.showSnackbar({
            text: 'Add Packing Shipment Successfully...',
            color: 'green',
            timeout: 2500
          })
          this.modalAddPacking.open = false
        } else {
          this.showSnackbar({
            text: 'Please selected package...!!!',
            color: 'red',
            timeout: 3000
          })
        }  
      }

      async SaveEditShipment(){
        this.showLoading({ text: 'Loading...'})
        try{
          const validation = await this.$validator.validateAll('ShipmentDetails')
          if (validation){
            const payload = {
              shipment_method: this.modalAddPacking.freightMethod,
              from: this.portOrigin,
              to: this.portDestination,
              bl_number: this.blNumber,
              departure_date: this.shipments_date.departureDate.date,
              arrival_date: this.shipments_date.arrivalDate.date,
              packages: [] 
            }
            for (const package_items of this.packing.items){
              payload.packages.push(package_items.id)
            }

            await ShipmentServis.updateShipment(this.id, payload)
          } else {
            this.showSnackbar({
              text: 'Please fill in all fields shipment details...!!!',
              color: 'red',
              timeout: 3000
            })
          }
        } catch (error) {
          this.catchHandler(error)
        } finally {
          this.closeLoading()
          this.$router.push('/shipment-acn')
        }
      } 
      
}
