import BaseService from '@/services/BaseService'

class ShipmentListService extends BaseService {
  constructor() {
    super()
  }

  // Shipment List Index
  getShipmentListIndex(opts = {}) {
    this.endPoint = 'api/v2/shipments'
    return this.get(opts).then(response => {
      return response
    })
  }

  // Packing List 
  getPackingList(opts = {}) {
    this.endPoint = 'api/v2/package'
    return this.get(opts).then(response => {
      return response
    })
  }

  getPacking(opts = {}) {
    this.endPoint = 'api/v2/packing-list'
    return this.get(opts).then(response => {
      return response
    })
  }

  // search shipment id
  getSearchUser(opts = {}) {
    this.endPoint = 'api/v2/orders/admin'
    return this.get(opts).then(response => {
      return response
    })
  }

  // View Status
  async getStatus(opts = {}) {
    this.endPoint = 'api/v2/statuses'
    return this.get(opts).then(response => {
      return response
    })
  }
  
  // Edit Status
  async changeStatus(payload, id){
    this.endPoint = `api/v2/shipments/${id}/set-status`
    return this.post(payload)
  }

  // Shipment List Detail
  async getShipmentListDetail(id: string | number, opts = {}) {
    this.endPoint = `api/v2/shipments/${id}`
    return await this.get(opts)
  }

  // Shipment List Routes
  async updateShipmentHistory(id: string | number, data: object, opts = {}) {
    this.endPoint = `shipments/shipment-history/${id}`
    return this.put(data, opts).then(response => {
      return response
    })
  }

  async addShipmentHistory(id: string | number, data: object, opts = {}) {
    this.endPoint = `shipments/${id}/shipment-history`
    return await this.post(data, opts)
  }

  async bulkHistoriesUpdate(data: object, opts = {}) {
    this.endPoint = 'shipment-histories/bulk-updates'
    return await this.put(data, opts)
  }

  async updateShipmentHistoryStatus(id: string | number, data: object, opts = {}) {
    this.endPoint = `shipments/shipment-history/${id}`
    return this.put(data, opts).then(response => {
      return response
    })
  }


  // Shipment List Create
  async createShipment(data: object) {
    this.endPoint = '/api/v2/shipments'
    return this.post(data).then(response => {
      return response.data
    })
  }

  async getPackageNotShipment(opts = {}) {
    this.endPoint = '/api/v2/package'
    return await this.get(opts)
  }

  async updateShipment(id : string | number, data : object){
    this.endPoint = `/api/v2/shipments/${id}`
    return this.put(data).then(response => {
      return response
    })
  }

  async deleteShipment(id : string | number){
    this.endPoint = '/api/v2/shipments'
    return this.delete(id).then(response => {
      return response
    })
  }  
}

export default new ShipmentListService()
